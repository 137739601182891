ul.answer_list li {
    padding: 2em;
    margin: 1em;
    list-style: none;
}

.masked-answer-container {
    padding: 2em;
    margin: 2em;
}

.masked-answer-actions button {
    margin: 1em;
}

.blurred-content {
    color: transparent;
    text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 10px;
}
